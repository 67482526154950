import { useFetchPromptAnswerByIsDashboard } from "@/queries/promptAnswers"
import ProcessingScreen from "./ProcessingScreen"
import LoadingIndicator from "@components/loading-indicator/LoadingIndicator"
import IncompleteScreen from "./IncompleteScreen"
import DashboardReport from "./DashboardReport"
import { PushPermission } from "./PushPermission"
import { BigNorthstar } from "./BigNorthstar/BigNorthStar"
import AskCoach from "./AskCoach"

export function Dashboard() {
  const { data: promptAnswer, isLoading } = useFetchPromptAnswerByIsDashboard()

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (!promptAnswer) {
    return <IncompleteScreen />
  }

  if (promptAnswer && (promptAnswer.isWaitingHumanCoach === undefined || promptAnswer.isWaitingHumanCoach === true)) {
    return <ProcessingScreen />
  }

  return (
    <div className="p-2 md:p-6">
      {promptAnswer && promptAnswer.isWaitingHumanCoach === false && (
        <>
          <PushPermission />
          <BigNorthstar />
          <AskCoach />
          <DashboardReport prompt={promptAnswer} />
        </>
      )}
    </div>
  )
}